import React, { ReactNode, useState } from "react";
import clsx from "clsx";

type FaqItem = {
  readonly id: string;
  readonly question: string;
  readonly html?: string;
  readonly children?: ReactNode;
};

type FaqsAccordionProps = {
  readonly faqs: readonly FaqItem[];
};

function FaqsAccordion({ faqs }: FaqsAccordionProps) {
  const [openFaqId, setOpenFaqId] = useState<string | undefined>();
  return (
    <div className="sqs-block accordion-block sqs-block-accordion">
      <div className="sqs-block-content">
        <ul
          className="accordion-items-container"
          data-should-allow-multiple-open-items=""
          data-is-divider-enabled="true"
          data-is-first-divider-visible="true"
          data-is-last-divider-visible="true"
          data-is-expanded-first-item=""
          data-accordion-title-alignment="left"
          data-accordion-description-alignment="left"
          data-accordion-description-placement="left"
          data-accordion-icon-placement="right"
        >
          {faqs.map((faq, i) => (
            <li
              key={faq.id}
              className="accordion-item"
              data-is-open={faq.id === openFaqId ? "true" : "false"}
            >
              {i === 0 && (
                <div
                  className="accordion-divider accordion-divider--top"
                  aria-hidden="true"
                  style={{
                    height: 1,
                    opacity: 1,
                  }}
                />
              )}
              <h3 className="accordion-item__title-wrapper" aria-level={3}>
                <button
                  type="button"
                  onClick={() =>
                    setOpenFaqId((p) => (p === faq.id ? undefined : faq.id))
                  }
                  className="accordion-item__click-target"
                  aria-expanded={faq.id === openFaqId ? "true" : "false"}
                  style={{
                    paddingTop: 30,
                    paddingBottom: 30,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  id={`button-block-${faq.id}`}
                  aria-controls={`dropdown-block-${faq.id}`}
                >
                  <span
                    className="accordion-item__title"
                    style={{ paddingRight: 14 }}
                  >
                    {faq.question}
                  </span>
                  <div
                    className="accordion-icon-container"
                    data-is-open="false"
                    aria-hidden="true"
                    style={{
                      height: 14,
                      width: 14,
                    }}
                  >
                    <div className="plus">
                      <div
                        className="plus__horizontal-line"
                        style={{ height: 1 }}
                      ></div>
                      <div
                        className="plus__vertical-line"
                        style={{ height: 1 }}
                      ></div>
                    </div>
                  </div>
                </button>
              </h3>
              <div
                className={clsx("accordion-item__dropdown", {
                  "accordion-item__dropdown--open": openFaqId === faq.id,
                })}
                role="region"
                id={`dropdown-block-${faq.id}`}
                aria-labelledby={`button-block-${faq.id}`}
              >
                <div
                  className="accordion-item__description"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 30,
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: "70%",
                    maxWidth: 300,
                  }}
                  dangerouslySetInnerHTML={
                    faq.html ? { __html: faq.html } : undefined
                  }
                >
                  {faq.children}
                </div>
              </div>
              <div
                className="accordion-divider"
                aria-hidden="true"
                style={{
                  height: 1,
                  opacity: 1,
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FaqsAccordion;
