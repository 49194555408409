import React from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { Link } from "gatsby";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";
import FaqsAccordion from "~/components/FaqsAccordion";

const allFaqs = [
  {
    slug: "about",
    name: "About ProblemScape",
    faqs: [
      {
        id: "about-1",
        question: "What is ProblemScape?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            ProblemScape is a problem-based learning game for introductory
            algebra that is centered on applications to make math relevant.
          </p>
        ),
      },
      {
        id: "about-2",
        question: "What is in ProblemScape?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            ProblemScape is an integrated learning environment that has fully
            research-guided content including videos and animations, practice,
            worked examples, challenge games, reviews, and other innovative
            components (teach-to-learn) that promote deep engagement with math
            concepts.
          </p>
        ),
      },
      {
        id: "about-3",
        question: "What topics are covered?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            ProblemScape for introductory algebra covers exponents, order of
            operations, writing arithmetic expressions, variables, writing
            algebraic expressions, substituting values for variables and
            evaluating expressions, finding equivalent expressions, checking for
            true equations and inequalities, solving simple equations in one
            variable, writing inequalities, and modeling dependent/independent
            variables with tables, equations, and graphs.
          </p>
        ),
      },
    ],
  },
  {
    slug: "learning",
    name: "Learning & pacing",
    faqs: [
      {
        id: "1",
        question: "I dont know how to answer a question! How can I learn?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            The Xpert notebook button on the left will open a learning resource
            that has videos as well as worked examples to help you understand
            the concepts and strategies needed for solving problems.
          </p>
        ),
      },
      {
        id: "2",
        question:
          "How can I get better at solving problems based on a particular concept?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            The Xpert notebook button on the left will open a learning resource
            that not only has videos and worked examples that will help you
            learn but also has a multi-level practice to push you towards
            mastery.
          </p>
        ),
      },
      {
        id: "3",
        question: "Why do I need to follow a particular process?",
        children: (
          <>
            <p style={{ whiteSpace: "pre-wrap" }}>
              Why is there an order of operations? Why can only ‘like terms’ be
              combined? Questions like these crop up constantly when we are
              learning math and you’ll be able to find the answers to some of
              these by finding the Ypert diary pages that are scattered
              throughout the game. Watching a Ypert video will even earn you a
              funny hat that your avatar can wear in the game!{" "}
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
              If you have a question for which you didn’t find an answer, please
              drop us a note at info@roundedlearning.com and we’ll try to make a
              Ypert diary page for you! You can even choose the reward that will
              be given to those who watch the video in the game!
            </p>
          </>
        ),
      },
      {
        id: "4",
        question:
          "How should I pace myself? Can I just quickly play through all the chapters to finish the adventure?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            Well, that is one way to play the game! However, a quick run-through
            will make you miss out on some of the content in the game. You’ll
            get a more robust understanding of concepts that will stay with you
            for longer, and help you learn harder concepts, if you take your
            time to try all the practice levels in the Xpert notebook, look
            through the videos and worked examples, do the challenge problems
            and reviews, and find the Ypert diary pages to watch the videos.
          </p>
        ),
      },
      {
        id: "5",
        question: "How does ProblemScape align with curriculum standards?",
        children: (
          <p style={{ whiteSpace: "pre-wrap" }}>
            ProblemScape: Value of Xperts aligns with the Common Core and other
            state standards for introductory algebra. A map of game content with
            the Common Core State Standards can be seen{" "}
            <a href="/map-to-state-standards/">here</a>.
          </p>
        ),
      },
    ],
  },
];

function ProblemScapePage() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const possibleCategorySlug = searchParams.get("category");
  const activeCategory =
    allFaqs.find((faq) => faq.slug === possibleCategorySlug) ?? allFaqs[0];

  return (
    <>
      <ProblemScapeGameHeader />
      <section className="Index-page">
        <div className="Index-page-content">
          <div className="sqs-layout sqs-grid-12 columns-12" data-type="page">
            <div className="row sqs-row">
              <div className="col sqs-col-12">
                {/* <div className="sqs-block html-block sqs-block-html">
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      How to download ProblemScape
                      <br />
                      &amp; create an account:
                    </h2>
                  </div>
                </div> */}
                <div className="row sqs-row">
                  <div className="col sqs-col-3 span-3">
                    {allFaqs.map((category, i) => (
                      <div
                        key={category.slug}
                        className="sqs-block button-block sqs-block-button"
                      >
                        <div className="sqs-block-content">
                          <div
                            className="sqs-block-button-container sqs-block-button-container--left"
                            data-animation-role="button"
                            data-alignment="left"
                            data-button-size="small"
                            data-button-type="tertiary"
                            id="yui_3_17_2_1_1671159857644_4629"
                          >
                            <Link
                              to={
                                i === 0
                                  ? "/stepbystep/"
                                  : `/stepbystep?category=${encodeURIComponent(category.slug)}`
                              }
                              className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element"
                              data-initialized="true"
                            >
                              {category.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col sqs-col-9">
                    <FaqsAccordion faqs={activeCategory.faqs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export function Head() {
  return <Seo title="ProblemScape" />;
}
export default ProblemScapePage;
